/* eslint-disable @typescript-eslint/naming-convention */
import type { TextElement } from '@studiobuki/web-core/lib/book-common';
import {
  APJapanesefont_black_21,
  APJapanesefont_black_34,
  APJapanesefont_black_44,
  ArmedBananafont_black_20,
  Huifont_black_20,
  LightNovelPOPv2_4D3B43_27,
  ZenMaruGothicBoldKunchan_138541_15,
  ZenMaruGothicBoldKunchan_3B4171_17,
  ZenMaruGothicBoldKunchan_black_14,
  ZenMaruGothicBoldKunchan_black_22,
  ZenMaruGothicBoldKunchan_black_61,
  ZenMaruGothicBoldKunchan_dc9940_17,
  ZenMaruGothicBoldKunchan_white_14,
} from './_styles';

const SEGMENT_0_text_value_1 = '{{heroName}}{{kidstitleIndex}}';

export const SEGMENT_0_text_1: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_black_61,
  text: SEGMENT_0_text_value_1,
  width: 467,
  x: 335,
  y: 209,
  xPdf: 335,
  yPdf: 209,
};
const SEGMENT_0_text_value_2 = 'せかいで見つけた　たからもの';

export const SEGMENT_0_text_2: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_black_22,
  text: SEGMENT_0_text_value_2,
  width: 467,
  x: 346,
  y: 288,
  xPdf: 346,
  yPdf: 288,
};

const SEGMENT_2_text_value_1 = `{{heroName}}{{kidstitle}}は　紙ひこうきを　おっています。`;

export const SEGMENT_2_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_2_text_value_1,
  x: 42,
  y: 48,
};
const SEGMENT_2_text_value_2 = 'うーん、なかなか　うまく　とばないなぁ。';

export const SEGMENT_2_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_2_text_value_2,
  x: 342,
  y: 135,
};

const SEGMENT_3_text_value_1 =
  'よぅし、うまくできた！きっとこれなら　だいじょうぶ！';

export const SEGMENT_3_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_3_text_value_1,
  x: 245,
  y: 162,
};
const SEGMENT_3_text_value_2 =
  'こんどは　もっと高く、もっととおくまで　とびますように。';

export const SEGMENT_3_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_3_text_value_2,
  x: 338,
  y: 202,
};

const SEGMENT_3_text_value_3 = 'さぁ、せーの！';

export const SEGMENT_3_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_3_text_value_3,
  x: 676,
  y: 530,
};

const SEGMENT_4_text_value_1 = 'えーい！';

export const SEGMENT_4_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_4_text_value_1,
  x: 221,
  y: 90,
};

const SEGMENT_5_text_value_1 =
  'あっ、すごいぞ！\n風をつかまえて　あんなに高く　とんだ！';

export const SEGMENT_5_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_5_text_value_1,
  x: 240,
  y: 307,
};
const SEGMENT_5_text_value_2 = 'その時です！';

export const SEGMENT_5_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_5_text_value_2,
  x: 658,
  y: 517,
};

const SEGMENT_6_text_value_1 = 'あれ？ここは？';

export const SEGMENT_6_text_1: TextElement = {
  align: 'left',
  style: APJapanesefont_black_34,
  text: SEGMENT_6_text_value_1,
  x: 106,
  y: 38,
};

const SEGMENT_6_text_value_2 =
  'まどからは、大きな　つばさがみえます。\nパスポートと、とうじょうけんを　もっています。\nもしかしてこれは、ひこうきの中！';

export const SEGMENT_6_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_6_text_value_2,
  x: 38,
  y: 367,
};
const SEGMENT_6_text_value_3 =
  '「ごとうじょう　ありがとうございます。間もなく　りりくいたします」\nと、アナウンスがきこえました！';

export const SEGMENT_6_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_6_text_value_3,
  x: 135,
  y: 485,
};
const SEGMENT_7_text_value_1 = 'ということは、今から空を　とんじゃうの？';

export const SEGMENT_7_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_7_text_value_1,
  x: 481,
  y: 23,
};
const SEGMENT_7_text_value_2 = '{{heroName}}{{kidstitle}}';

export const SEGMENT_7_text_2: TextElement = {
  align: 'center',
  style: LightNovelPOPv2_4D3B43_27,
  text: SEGMENT_7_text_value_2,
  width: 325,
  rotation: 19,
  x: 458,
  y: 182,
};

const SEGMENT_8_text_value_1 =
  'ひこうきは　ゆっくりと　うごきだし、\nぐんぐんと　スピードをあげて　走り出しました！';

export const SEGMENT_8_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_8_text_value_1,
  x: 36,
  y: 58,
};

const SEGMENT_8_text_value_2_1 = 'あっ！';

export const SEGMENT_8_text_2_1: TextElement = {
  align: 'left',
  style: APJapanesefont_black_44,
  text: SEGMENT_8_text_value_2_1,
  x: 291,
  y: 433,
};
const SEGMENT_8_text_value_2_2 = 'ふわっとういた！';

export const SEGMENT_8_text_2_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_8_text_value_2_2,
  x: 416,
  y: 461,
};

const SEGMENT_8_text_value_3 = '上へ、上へ。';

export const SEGMENT_8_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_8_text_value_3,
  x: 599,
  y: 491,
};

const SEGMENT_8_text_value_4 = 'いったい　どこまで　あがるんだろう。';

export const SEGMENT_8_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_8_text_value_4,
  x: 406,
  y: 546,
};

const SEGMENT_9_text_value_1 = 'どんどん　じめんが　遠ざかっていきます。';

export const SEGMENT_9_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_9_text_value_1,
  x: 41,
  y: 45,
};
const SEGMENT_9_text_value_2 =
  '白いモヤモヤしたものに　つつまれたと　思ったら、';

export const SEGMENT_9_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_9_text_value_2,
  x: 102,
  y: 86,
};
const SEGMENT_9_text_value_3 = 'ひろくひろく、青い空が　あらわれました！';

export const SEGMENT_9_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_9_text_value_3,
  x: 444,
  y: 457,
};
const SEGMENT_9_text_value_4 =
  '白いモヤモヤは、なんと、雲！雲より　高いところにいるなんて！';

export const SEGMENT_9_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_9_text_value_4,
  x: 275,
  y: 505,
};

const SEGMENT_10_text_value_1 =
  '「きないしょくを　どうぞ」と、きゃくしつじょうむいんさんが　ごはんをくれました。';

export const SEGMENT_10_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_10_text_value_1,
  x: 48,
  y: 43,
};

const SEGMENT_11_text_value_1 =
  '今、空の上を　とんでいるって、本当なんでしょうか？';

export const SEGMENT_11_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_11_text_value_1,
  x: 170,
  y: 18,
};
const SEGMENT_11_text_value_2 = 'なんだか　しんじられない　きもちです。';

export const SEGMENT_11_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_11_text_value_2,
  x: 504,
  y: 75,
};
const SEGMENT_11_text_value_3 =
  'えいがも見れて、おいしいごはんも　食べられる。\nこんなに　楽しいんですから！';

export const SEGMENT_11_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_11_text_value_3,
  x: 446,
  y: 496,
};

const SEGMENT_12_16_text_value_1 =
  'しばらくすると「間もなく　ちゃくりくたいせいに入ります」と、\nパイロットの　アナウンスが　聞こえました。';

export const SEGMENT_12_16_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_16_text_value_1,
  x: 36,
  y: 27,
};
const SEGMENT_12_16_text_value_2 =
  'ひこうきは　こんどは　上から下に　むかっていきます。';

export const SEGMENT_12_16_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_16_text_value_2,
  x: 349,
  y: 125,
};

const SEGMENT_12_16_text_value_3 =
  'すると！つばさの下に　まちが見えてきました！';
export const SEGMENT_12_16_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_16_text_value_3,
  x: 368,
  y: 525,
};

const SEGMENT_12_17_text_value_1 =
  'しばらくすると「間もなく　ちゃくりくたいせいに入ります」と、\nパイロットの　アナウンスが　聞こえました。';

export const SEGMENT_12_17_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_17_text_value_1,
  x: 36,
  y: 27,
};
const SEGMENT_12_17_text_value_2 =
  'ひこうきは　こんどは　上から下に　むかっていきます。';

export const SEGMENT_12_17_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_17_text_value_2,
  x: 349,
  y: 125,
};

const SEGMENT_12_17_text_value_3 =
  'すると！つばさの下に　まちが見えてきました！';
export const SEGMENT_12_17_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_17_text_value_3,
  x: 368,
  y: 525,
};

const SEGMENT_12_18_text_value_1 =
  'しばらくすると「間もなく　ちゃくりくたいせいに入ります」と、\nパイロットの　アナウンスが　聞こえました。';

export const SEGMENT_12_18_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_18_text_value_1,
  x: 36,
  y: 27,
};
const SEGMENT_12_18_text_value_2 =
  'ひこうきは　こんどは　上から下に　むかっていきます。';

export const SEGMENT_12_18_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_18_text_value_2,
  x: 349,
  y: 125,
};

const SEGMENT_12_18_text_value_3 =
  'すると！つばさの下に　まちが見えてきました！';
export const SEGMENT_12_18_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_12_18_text_value_3,
  x: 368,
  y: 525,
};

const SEGMENT_13_19_text_value_1 = 'ひこうきは　じめんに　ちゃくりくしました。';
export const SEGMENT_13_19_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_13_19_text_value_1,
  x: 404,
  y: 533,
};

const SEGMENT_13_20_text_value_1 = 'ひこうきは　じめんに　ちゃくりくしました。';
export const SEGMENT_13_20_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_13_20_text_value_1,
  x: 404,
  y: 533,
};
const SEGMENT_13_21_text_value_1 = 'ひこうきは　じめんに　ちゃくりくしました。';
export const SEGMENT_13_21_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_13_21_text_value_1,
  x: 404,
  y: 533,
};

const SEGMENT_20_text_value_1 =
  'つぎの国にいくために、{{heroName}}{{kidstitle}}は　ひこうきに　のり込みます。';

export const SEGMENT_20_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_20_text_value_1,
  x: 48,
  y: 54,
};
const SEGMENT_20_text_value_2 =
  'よく見れば、ひこうきって　なんて大きいんでしょう！';

export const SEGMENT_20_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_20_text_value_2,
  x: 192,
  y: 140,
};

const SEGMENT_21_text_value_1 = 'これが、空をとぶなんて、すごいなぁ。';

export const SEGMENT_21_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_21_text_value_1,
  x: 497,
  y: 135,
};
const SEGMENT_21_text_value_2 =
  'こんなに　大きくっておもそうな　ひこうきが　空をとべるんなら、\n{{heroName}}{{kidstitle}}も　とべるんじゃないの？と、{{heroName}}{{kidstitle}}は　思いました。';

export const SEGMENT_21_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_21_text_value_2,
  x: 168,
  y: 521,
};

const SEGMENT_28_text_value_1 =
  '「すごい！せかいには　こんなところが　あるなんて！」\nくうこうで　{{heroName}}{{kidstitle}}は　思いました。';

export const SEGMENT_28_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_28_text_value_1,
  x: 30,
  y: 314,
};
const SEGMENT_28_text_value_2 =
  'そのとき、\n「楽しかったかい？」とパイロットが　{{heroName}}{{kidstitle}}に　聞きました。';

export const SEGMENT_28_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_28_text_value_2,
  x: 33,
  y: 520,
};

const SEGMENT_29_text_value_1 =
  '「うん、とっても！」{{heroName}}{{kidstitle}}が　答えます。';

export const SEGMENT_29_text_1: TextElement = {
  align: 'right',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_29_text_value_1,
  width: 842,
  x: -44,
  y: 104,
};
const SEGMENT_29_text_value_2 =
  '「せかいは、広い。{{heroName}}{{kidstitle}}が　知らないこと、見たことがないものが、たくさんある。\nでも、{{heroName}}{{kidstitle}}の　{{strengthE}}があれば、たからものを　見つけることができるよ」';

export const SEGMENT_29_text_2: TextElement = {
  align: 'right',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_29_text_value_2,
  width: 842,
  x: -40,
  y: 172,
};

const SEGMENT_29_text_value_3 =
  '「はい、これは　{{heroName}}{{kidstitle}}の　ものだね」\nそういって　パイロットは　紙ひこうきを　{{heroName}}{{kidstitle}}に　手わたしました。';

export const SEGMENT_29_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_29_text_value_3,
  x: 185,
  y: 520,
};

const SEGMENT_30_text_value_1 =
  'そして、気がつくとそこは、{{heroName}}{{kidstitle}}の　へやの中。';

export const SEGMENT_30_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_30_text_value_1,
  x: 61,
  y: 72,
};
const SEGMENT_30_text_value_2 =
  '「ごはんだよー！」\nと、{{heroName}}{{kidstitle}}を　よぶ　声がします。';

export const SEGMENT_30_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_30_text_value_2,
  x: 129,
  y: 139,
};
const SEGMENT_30_text_value_3 = 'もしかして、ぜんぶ　ゆめだったのでしょうか？';

export const SEGMENT_30_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_30_text_value_3,
  x: 230,
  y: 518,
};

const SEGMENT_31_text_value_1 = 'ゆめだったのかどうかは　わかりません。';

export const SEGMENT_31_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_31_text_value_1,
  x: 348,
  y: 114,
};
const SEGMENT_31_text_value_2 =
  'でも{{heroName}}{{kidstitle}}は、\n{{Country01}}も、{{Country02}}も　おぼえている　気がします。';

export const SEGMENT_31_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_31_text_value_2,
  x: 130,
  y: 429,
};
const SEGMENT_31_text_value_3 =
  'つくえの上には、もらった　プレゼントが　おいてあります。';

export const SEGMENT_31_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_31_text_value_3,
  x: 360,
  y: 531,
};

const SEGMENT_32_text_value_1 =
  '「今いくよー！」{{heroName}}{{kidstitle}}は　へんじをしました。';

export const SEGMENT_32_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_32_text_value_1,
  x: 50,
  y: 475,
};
const SEGMENT_32_text_value_2 =
  'そして、手にもっていた、さっきとばしたはずの　紙ひこうきを　もういちど、\n力づよく　とばしました。';

export const SEGMENT_32_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_32_text_value_2,
  x: 57,
  y: 516,
};

const SEGMENT_33_text_value_1 = '{{heroName}}{{kidstitle}}！';

export const SEGMENT_33_text_1: TextElement = {
  align: 'center',
  style: APJapanesefont_black_21,
  text: SEGMENT_33_text_value_1,
  rotation: -9,
  width: 178,
  x: 188,
  y: 146,
};
const SEGMENT_33_text_value_2 = '「とんでけー！もっと、とおくまで！」';

export const SEGMENT_33_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_33_text_value_2,
  x: 481,
  y: 34,
};

const SEGMENT_34_text_value_1 =
  'いつまでも　{{strengthF}}\n{{heroName}}{{kidstitle}}で　いてね。\nまた　あおうね。\n{{from1}}より';

export const SEGMENT_34_text_1: TextElement = {
  align: 'left',
  style: Huifont_black_20,
  text: SEGMENT_34_text_value_1,
  rotation: 11,
  x: 127,
  y: 40,
};
const SEGMENT_34_text_value_2 =
  '{{strengthF2}}\n{{heroName}}{{kidstitle}}は\nとっても　すてきだよ。\nきてくれて　ありがとう。\n{{from2}}';

export const SEGMENT_34_text_2: TextElement = {
  align: 'center',
  style: ArmedBananafont_black_20,
  text: SEGMENT_34_text_value_2,
  width: 500,
  rotation: -12,
  x: 340,
  y: 489,
};

const SEGMENT_14_99031_text_value_1 =
  '「うわ！たいようが　まぶしい！」{{heroName}}{{kidstitle}}は\nハワイのまち、ホノルルに　着きました。\n青い空が　広がっています！なんだか　気もちいい。\nマノという子が　お花の　くびかざりを　くれました。';

export const SEGMENT_14_99031_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_14_99031_text_value_1,
  x: 22,
  y: 143,
};
const SEGMENT_14_99031_text_value_2 = 'アロハ！\n{{heroName}}{{kidstitle}}！';

export const SEGMENT_14_99031_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_dc9940_17,
  text: SEGMENT_14_99031_text_value_2,
  x: 223,
  y: 273,
  rotation: -10,
};

const SEGMENT_14_99041_text_value_1 =
  '「ついた！うわー！にぎやかな　まち　だなぁ！」\n{{heroName}}{{kidstitle}}は　イギリスのしゅと、\nロンドンに　着きました。むかえてくれたのは、\nここにすむ　アンナという子。';

export const SEGMENT_14_99041_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_14_99041_text_value_1,
  x: 22,
  y: 143,
};
const SEGMENT_14_99041_text_value_2 = 'ハロー！\n{{heroName}}{{kidstitle}}！';

export const SEGMENT_14_99041_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_3B4171_17,
  text: SEGMENT_14_99041_text_value_2,
  x: 217,
  y: 269,
  rotation: -10,
};

const SEGMENT_14_99051_text_value_1 =
  '「うぅぅさむい・・！昼間につく、って聞いたのに\nくらいのはなんで？まるで、夜みたい！」\n{{heroName}}{{kidstitle}}は　フィンランドの　ヘルシンキに\nとうちゃくし、ニコという子と　会いました。';

export const SEGMENT_14_99051_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_14_99051_text_value_1,
  x: 22,
  y: 143,
};
const SEGMENT_14_99051_text_value_2 = 'モイ！\n{{heroName}}{{kidstitle}}！';

export const SEGMENT_14_99051_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_dc9940_17,
  text: SEGMENT_14_99051_text_value_2,
  x: 205,
  y: 274,
  rotation: -10,
};

const SEGMENT_15_99032_text_value_1 =
  '「ここは　海にかこまれた　しまなの。ほら、なみの音が　聞こえるでしょう？」';

export const SEGMENT_15_99032_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99032_text_value_1,
  x: 213,
  y: 34,
};
const SEGMENT_15_99032_text_value_2 =
  '「{{heroName}}{{kidstitle}}に　見せたいものがあるの！\nもうすぐその時間だから、いそいで行こう！」';

export const SEGMENT_15_99032_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99032_text_value_2,
  x: 431,
  y: 158,
};

const SEGMENT_15_99042_text_value_1 =
  '「あの赤い　２かいだてのバスは、ロンドンバス。\n　　　　　　大きな時計台は、ビッグベンっていうよ」';

export const SEGMENT_15_99042_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99042_text_value_1,
  x: 330,
  y: 51,
};

const SEGMENT_15_99042_text_value_2 =
  '「ここ来たら、あれも見なきゃね。{{heroName}}{{kidstitle}}、行こっか！」';

export const SEGMENT_15_99042_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99042_text_value_2,
  x: 327,
  y: 306,
};

const SEGMENT_15_99052_text_value_1 =
  '「はは、寒いよね！フィンランドの　４分の１は　ほっきょくけんだもん。\nまだ昼間だけど　暗いのは　たいようが　早くしずんだからなんだ！」';

export const SEGMENT_15_99052_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99052_text_value_1,
  x: 242,
  y: 33,
};
const SEGMENT_15_99052_text_value_2 =
  '「{{heroName}}{{kidstitle}}、とっておきのものを　見せてあげる！\n近くのまちまで　電車でいこう」';

export const SEGMENT_15_99052_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99052_text_value_2,
  x: 379,
  y: 136,
};
const SEGMENT_15_99052_text_value_3 =
  '「そのあとは、犬ぞりだよ！さぁ行こう！」';

export const SEGMENT_15_99052_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_15_99052_text_value_3,
  x: 487,
  y: 535,
};

const SEGMENT_16_99033_text_value_1 =
  'とちゅう、泣いている　子に　出会いました。\n「パパからもらった　大切なネックレスを　なくしちゃったの！」\nそれは　たいへん！';

export const SEGMENT_16_99033_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99033_text_value_1,
  x: 41,
  y: 47,
};
const SEGMENT_16_99033_text_value_2 =
  '「さがしてあげたいけど、時間に　間に合わないかも」と、ふあんそうな　マノ。';

export const SEGMENT_16_99033_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99033_text_value_2,
  x: 66,
  y: 148,
};
const SEGMENT_16_99033_text_value_3 =
  'でも、どんな時も　やさしい　{{heroName}}{{kidstitle}}は\n『大切な　ものなんだよね。いっしょに　さがそう！』と言い、\nみんなで　いっしょうけんめい、すなの中を　さがしました。';

export const SEGMENT_16_99033_text_3: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99033_text_value_3,
  width: 842,
  x: 0,
  y: 478,
};

const SEGMENT_16_99035_text_value_1 =
  'ビーチに向かう　ふたり。\nそこで、「あっちゃー！もうすぐだ！間に合わないかも！」\nと、マノが言います。';

export const SEGMENT_16_99035_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99035_text_value_1,
  x: 200,
  y: 30,
};
const SEGMENT_16_99035_text_value_2 =
  '「あのあなを　通ったら　近いんだけど\nかいぶつが　すんでるって　聞くし、\nぶきみで、だれも　近よったことがないの」';

export const SEGMENT_16_99035_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99035_text_value_2,
  x: 413,
  y: 113,
};

const SEGMENT_16_99037_text_value_1 =
  'ビーチに向かう　{{heroName}}{{kidstitle}}と　マノ。\nすると、バチン！！！と、音がしました。';

export const SEGMENT_16_99037_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99037_text_value_1,
  x: 34,
  y: 58,
};

const SEGMENT_16_99037_text_value_2 =
  'なんとまぁ！\nマノの　ビーチサンダルの\nひもが　切れてしまいました！';

export const SEGMENT_16_99037_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99037_text_value_2,
  x: 28,
  y: 502,
};
const SEGMENT_16_99037_text_value_3 =
  '「あっちゃー、これじゃはけないな。\nどうしよう・・」';

export const SEGMENT_16_99037_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99037_text_value_3,
  x: 468,
  y: 364,
};
const SEGMENT_16_99037_text_value_4 = 'これは　こまりました！';

export const SEGMENT_16_99037_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99037_text_value_4,
  x: 525,
  y: 504,
};

const SEGMENT_16_99043_text_value_1 =
  '行くとちゅう、赤い電話ボックスの中で\n小さな　犬を　見つけました。';

export const SEGMENT_16_99043_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99043_text_value_1,
  x: 43,
  y: 35,
};

const SEGMENT_16_99043_text_value_2 = 'どうやら、まいごみたい。';

export const SEGMENT_16_99043_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99043_text_value_2,
  x: 143,
  y: 99,
};
const SEGMENT_16_99043_text_value_3 =
  '「でも、かいぬしを　さがしてたら\n間に合わないかもしれないよ」とアンナは言います。';

export const SEGMENT_16_99043_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99043_text_value_3,
  x: 350,
  y: 210,
};
const SEGMENT_16_99043_text_value_4 =
  'でも、いつだって　やさしい　{{heroName}}{{kidstitle}}は、\n「このまま　おいていけないよ！」と言い、\nかいぬしを　さがすことにしました。';

export const SEGMENT_16_99043_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99043_text_value_4,
  x: 78,
  y: 450,
};
const SEGMENT_16_99043_text_value_5 =
  'しばらくして、かいぬしの　おばあさんを　見つけることができました！';

export const SEGMENT_16_99043_text_5: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99043_text_value_5,
  x: 248,
  y: 549,
};

const SEGMENT_16_99045_text_value_1 = '向かう　とちゅうのこと、';

export const SEGMENT_16_99045_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99045_text_value_1,
  x: 16,
  y: 36,
};

const SEGMENT_16_99045_text_value_2 =
  '「ここを通れば　近いんだけど・・」\nアンナが言います。';

export const SEGMENT_16_99045_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99045_text_value_2,
  x: 331,
  y: 79,
};
const SEGMENT_16_99045_text_value_3 =
  '「でも、このまわりは、オ、オバケがでるって\nうわさだから・・とおまわりしようよ」';

export const SEGMENT_16_99045_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99045_text_value_3,
  x: 74,
  y: 236,
};
const SEGMENT_16_99045_text_value_4 =
  'むかーしむかしからある、大きな　たてもの！\nたしかに、こわーい　ふんいきです！';

export const SEGMENT_16_99045_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99045_text_value_4,
  x: 131,
  y: 458,
};
const SEGMENT_16_99045_text_value_5 =
  '「ね？やめておこう？」と、アンナは　ふあんそう。';

export const SEGMENT_16_99045_text_5: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99045_text_value_5,
  x: 237,
  y: 520,
};

const SEGMENT_16_99047_text_value_1 = '向かう　とちゅうのこと、';

export const SEGMENT_16_99047_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99047_text_value_1,
  x: 28,
  y: 26,
};

const SEGMENT_16_99047_text_value_2 =
  '「ふふ、じつは　おなかが　すいちゃって」とアンナが　言います。\n「それなら　何か食べていこう！」と、{{heroName}}{{kidstitle}}。';

export const SEGMENT_16_99047_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99047_text_value_2,
  x: 19,
  y: 98,
};
const SEGMENT_16_99047_text_value_3 =
  '「いいね！でもあれも　これも食べたい・・\nうーん、まよっちゃう！どうしよう！」\nとアンナは　きめられません。\n\nおいしいものが　たくさんあるって、\nうれしいことですが、\nこれでは　なかなか　しゅっぱつ　できません。';

export const SEGMENT_16_99047_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99047_text_value_3,
  x: 407,
  y: 370,
};

const SEGMENT_16_99053_text_value_1 = '犬が　ソリを　ひいてくれるなんて！';

export const SEGMENT_16_99053_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99053_text_value_1,
  x: 25,
  y: 26,
};

const SEGMENT_16_99053_text_value_2 =
  'ワクワクしながら　{{heroName}}{{kidstitle}}と　ニコは\nソリに　とびのりました！さぁ、しゅっぱつ！';

export const SEGMENT_16_99053_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99053_text_value_2,
  x: 54,
  y: 278,
};
const SEGMENT_16_99053_text_value_3 = '・・でも、犬ぞりは　動きません。';

export const SEGMENT_16_99053_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99053_text_value_3,
  x: 391,
  y: 344,
};
const SEGMENT_16_99053_text_value_4 =
  'どうやら、いっぴきの　犬にとっては\nこれがはじめての　ソリひき。\nきんちょうしてしまったみたい。';

export const SEGMENT_16_99053_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99053_text_value_4,
  x: 456,
  y: 400,
};

const SEGMENT_16_99055_text_value_1 = '犬が　ソリを　ひいてくれるなんて！';

export const SEGMENT_16_99055_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99055_text_value_1,
  x: 61,
  y: 39,
};

const SEGMENT_16_99055_text_value_2 =
  'ワクワクしながら　{{heroName}}{{kidstitle}}と　ニコは\nソリに　とびのりました！さぁ、しゅっぱつ！';

export const SEGMENT_16_99055_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99055_text_value_2,
  x: 396,
  y: 201,
};
const SEGMENT_16_99055_text_value_3 = 'と思ったら、止まってしまいました！';

export const SEGMENT_16_99055_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99055_text_value_3,
  x: 297,
  y: 281,
};
const SEGMENT_16_99055_text_value_4 =
  '前に、とても　大きな犬が　まちかまえていて、みんな　こわがっています。\nこれでは　すすめそうに　ありません！';

export const SEGMENT_16_99055_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99055_text_value_4,
  x: 73,
  y: 508,
};

const SEGMENT_16_99057_text_value_1 = '犬が　ソリを　ひいてくれるなんて！';

export const SEGMENT_16_99057_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99057_text_value_1,
  x: 53,
  y: 28,
};

const SEGMENT_16_99057_text_value_2 =
  'ワクワクしながら　{{heroName}}{{kidstitle}}と　ニコは\nソリに　とびのりました！さぁ、しゅっぱつ！';

export const SEGMENT_16_99057_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99057_text_value_2,
  x: 428,
  y: 86,
};
const SEGMENT_16_99057_text_value_3 = 'と思ったら！';

export const SEGMENT_16_99057_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99057_text_value_3,
  x: 400,
  y: 356,
};
const SEGMENT_16_99057_text_value_4 =
  'なんと、だいじな　ソリが　こわれてしまいました！\n「これじゃあ　行けないな・・」と\nニコは　ざんねんそうに　言いました。';

export const SEGMENT_16_99057_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_16_99057_text_value_4,
  x: 409,
  y: 454,
};

const SEGMENT_17_99034_text_value_1 =
  'そして、{{heroName}}{{kidstitle}}は　かわった　石のようなものを見つけ、ひろいあげました。';

export const SEGMENT_17_99034_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99034_text_value_1,
  x: 174,
  y: 36,
};

const SEGMENT_17_99034_text_value_2 =
  '「さがしてた　つりばりネックレス！」\nぶじに　見つかりました！\n\n「それはここでは　しあわせを　つりあげる、\nというねがいが　こめられているんだ、ありがとう」\nと、その子のパパも　おれいを言いました。';

export const SEGMENT_17_99034_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99034_text_value_2,
  x: 421,
  y: 83,
};
const SEGMENT_17_99034_text_value_3 =
  'そして、「よければ　行きたいビーチまで　おくろう」と言い、\nボートに　{{heroName}}{{kidstitle}}と　マノを　のせていってくれました。';

export const SEGMENT_17_99034_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99034_text_value_3,
  x: 253,
  y: 524,
};

const SEGMENT_17_99036_text_value_1 =
  'でも、どんな時も　ゆうかんな　{{heroName}}{{kidstitle}}は\n「自分で　見てみないと、わからないよ！」\nと言って、あなを　よーく見てみました。';

export const SEGMENT_17_99036_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99036_text_value_1,
  x: 350,
  y: 24,
};

const SEGMENT_17_99036_text_value_2 =
  'そして　ニコっとわらってから\n「なぁんだ！ここはどうぶつの　通りみちだよ！\nこのさきは　ビーチにつながってる！行こう！」と言い、';

export const SEGMENT_17_99036_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99036_text_value_2,
  x: 317,
  y: 422,
};
const SEGMENT_17_99036_text_value_3 =
  'ふたりは　あなをくぐりぬけて　行きました。';

export const SEGMENT_17_99036_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99036_text_value_3,
  x: 322,
  y: 533,
};

const SEGMENT_17_99038_text_value_1 =
  'でもどんな時も　はっそうゆたかな　{{heroName}}{{kidstitle}}は\n何かひらめいたようで、ヤシの木の　葉っぱを　あつめ始めました。';

export const SEGMENT_17_99038_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99038_text_value_1,
  x: 95,
  y: 65,
};

const SEGMENT_17_99038_text_value_2 =
  'そうして、葉っぱを　あみ込んで　サンダルにつけたら、\nじゃじゃん！サンダルを　直してしまいました。';

export const SEGMENT_17_99038_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99038_text_value_2,
  x: 417,
  y: 203,
};
const SEGMENT_17_99038_text_value_3 =
  '「ふふ、おしゃれな　サンダル！」と、うれしそうなマノ。\nふたりは　かろやかに　歩いていきます。';

export const SEGMENT_17_99038_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99038_text_value_3,
  x: 73,
  y: 505,
};

const SEGMENT_17_99044_text_value_1 =
  '「電話をしていたら　おいてきてしまったのね！\nありがとう。おれいに　これをあげるわ」\nとおばあさんは言い、ロンドンバスのチケットをくれました。';

export const SEGMENT_17_99044_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99044_text_value_1,
  x: 81,
  y: 62,
};

const SEGMENT_17_99044_text_value_2 = 'これなら　間に合います！';

export const SEGMENT_17_99044_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99044_text_value_2,
  x: 354,
  y: 218,
};
const SEGMENT_17_99044_text_value_3 =
  '{{heroName}}{{kidstitle}}と　アンナは\nロンドンバスで、もくてきちに　向かいました。';

export const SEGMENT_17_99044_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99044_text_value_3,
  x: 404,
  y: 523,
};

const SEGMENT_17_99046_text_value_1 =
  'でも、どんな時も　ゆうかんな　{{heroName}}{{kidstitle}}は\n「いっしょに　行けば　だいじょうぶ！\nこわさは　心の中に　あるんだよ！行くよ！」\nと言い、アンナの手を　とりました。';

export const SEGMENT_17_99046_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99046_text_value_1,
  x: 68,
  y: 325,
};

const SEGMENT_17_99046_text_value_2 = 'そして　いっきに　かけぬけました！';

export const SEGMENT_17_99046_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99046_text_value_2,
  x: 480,
  y: 219,
};
const SEGMENT_17_99046_text_value_3 =
  '「ふぅ！そうだね、思ったほど　こわくなかったよ！」\nと、ほっとしたアンナ。\n　　　　　ふたりは　また、歩きだしました。';

export const SEGMENT_17_99046_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99046_text_value_3,
  x: 363,
  y: 507,
};

const SEGMENT_17_99048_text_value_1 =
  'でも、いつだって　はっそうゆたかな　{{heroName}}{{kidstitle}}は、\n「そうだ！こんなときこそ、あれだよ！」と言い、\nイギリスめいぶつの　アフタヌーンティーセットを　ちゅうもんしました。';

export const SEGMENT_17_99048_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99048_text_value_1,
  x: 265,
  y: 23,
};

const SEGMENT_17_99048_text_value_2 = 'するとどうでしょう！';

export const SEGMENT_17_99048_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99048_text_value_2,
  x: 398,
  y: 156,
};
const SEGMENT_17_99048_text_value_3 =
  'サンドイッチに　スコーン、スイーツや　こうちゃまで、\nイギリスの　おいしいものが　いっぺんに味わえるじゃありませんか！\nたしかに！これは　ナイスアイデア！';

export const SEGMENT_17_99048_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99048_text_value_3,
  x: 113,
  y: 214,
};
const SEGMENT_17_99048_text_value_4 =
  'ふたりは　おなかいっぱいになって\nしゅっぱつしました。';

export const SEGMENT_17_99048_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99048_text_value_4,
  x: 556,
  y: 525,
};

const SEGMENT_17_99054_text_value_1 =
  'そこで、どんなあいてにも　やさしい　{{heroName}}{{kidstitle}}は、\nふるえる犬に　かけより、そっと　だきしめました。';

export const SEGMENT_17_99054_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99054_text_value_1,
  x: 153,
  y: 18,
};

const SEGMENT_17_99054_text_value_2 =
  '「だいじょうぶだよ」と、\nやさしく　言いました。すると、';

export const SEGMENT_17_99054_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99054_text_value_2,
  x: 323,
  y: 96,
};
const SEGMENT_17_99054_text_value_3 =
  '犬は　あんしんし、\nしっぽを　ぶんぶんと　ふって、\n元気になりました！';

export const SEGMENT_17_99054_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99054_text_value_3,
  x: 103,
  y: 311,
};
const SEGMENT_17_99054_text_value_4 =
  '犬ぞりは　雪の上を　いきおいよく　かけていきます。';

export const SEGMENT_17_99054_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99054_text_value_4,
  x: 362,
  y: 522,
};

const SEGMENT_17_99056_text_value_1 =
  'そこで、どんなあいてにも　ゆうかんな　{{heroName}}{{kidstitle}}は、\nゆうきをだして　大きな犬に　ちかよりました。';

export const SEGMENT_17_99056_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99056_text_value_1,
  x: 337,
  y: 46,
};

const SEGMENT_17_99056_text_value_2 =
  'そして「もしかして・・、いっしょに行きたいの？」\nと、大きな犬に　声をかけました。';

export const SEGMENT_17_99056_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99056_text_value_2,
  x: 341,
  y: 110,
};
const SEGMENT_17_99056_text_value_3 = 'すると、';

export const SEGMENT_17_99056_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99056_text_value_3,
  x: 146,
  y: 215,
};
const SEGMENT_17_99056_text_value_4 =
  '大きな犬は　しっぽを　ぶんぶんふって　おおよろこび！';

export const SEGMENT_17_99056_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99056_text_value_4,
  x: 73,
  y: 284,
};
const SEGMENT_17_99056_text_value_5 =
  '大きな犬も　みんないっしょに　いきおいよく　かけ出しました。\nそのスピードの　はやいこと　はやいこと！';

export const SEGMENT_17_99056_text_5: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99056_text_value_5,
  x: 245,
  y: 537,
};

const SEGMENT_17_99058_text_value_1 =
  'でも、どんな時だって　はっそうゆたかな　{{heroName}}{{kidstitle}}は、\n「だいじょうぶ！ちょっと　くふうすれば　いいんだよ」と言い、';

export const SEGMENT_17_99058_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99058_text_value_1,
  x: 300,
  y: 21,
};

const SEGMENT_17_99058_text_value_2 =
  'トントントン、カンカンカン・・！\n近くにあったもので　あっという間に\nソリを　作りなおして　しまったのです。';

export const SEGMENT_17_99058_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99058_text_value_2,
  x: 140,
  y: 189,
};
const SEGMENT_17_99058_text_value_3 = 'すてきな　ソリができました！';

export const SEGMENT_17_99058_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99058_text_value_3,
  x: 273,
  y: 337,
};
const SEGMENT_17_99058_text_value_4 =
  'そして　犬ぞりは　いきおいよく　かけだしました。';

export const SEGMENT_17_99058_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_17_99058_text_value_4,
  x: 223,
  y: 558,
};

const SEGMENT_18_99039_text_value_1 =
  'もくてきの　ビーチに　着きました。\n「ふぅ、間に合ってよかった！ここからは　とってもきれいに　見えるの！」';

export const SEGMENT_18_99039_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99039_text_value_1,
  x: 55,
  y: 47,
};

const SEGMENT_18_99039_text_value_2 =
  'オレンジいろの　たいようが　海に　すい込まれていくような、ひのいり！\n光が　海にはんしゃして、それはそれは　とてもきれいです。';

export const SEGMENT_18_99039_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99039_text_value_2,
  x: 197,
  y: 160,
};
const SEGMENT_18_99039_text_value_3 =
  'そして　なみの音、しおのかおり、砂のかんしょく、ヤシの木のはが　ゆれる音、うみどりのさえずり、\nそれらも　かんじながら、{{heroName}}{{kidstitle}}は　ひのいりを　じっと見つめました。';

export const SEGMENT_18_99039_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99039_text_value_3,
  x: 25,
  y: 453,
};

const SEGMENT_18_99049_text_value_1 =
  '「ここはね、バッキンガムきゅうでん！この国の王さまが　すんでるの」とアンナ。';

export const SEGMENT_18_99049_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99049_text_value_1,
  x: 45,
  y: 32,
};

const SEGMENT_18_99049_text_value_2 =
  'たいそう　りっぱで　ごうかな　たてもの！';

export const SEGMENT_18_99049_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99049_text_value_2,
  x: 53,
  y: 409,
};
const SEGMENT_18_99049_text_value_3 =
  '「あの　えいへいたちは、王さまを　守っているの。\n今はこうたいしきの　時間。これを　見せたかったんだ」';

export const SEGMENT_18_99049_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99049_text_value_3,
  x: 53,
  y: 454,
};
const SEGMENT_18_99049_text_value_4 =
  '「へぇ！」{{heroName}}{{kidstitle}}は　目を丸くしました。\nきゅうでんに　王さまに　えいへい。まるで　おとぎ話のようです。';

export const SEGMENT_18_99049_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_18_99049_text_value_4,
  x: 53,
  y: 524,
};

const SEGMENT_18_99059_text_value_1 =
  '着いたところで　見えたのは、なんと、オーロラ！';

export const SEGMENT_18_99059_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_18_99059_text_value_1,
  x: 34,
  y: 40,
};

const SEGMENT_18_99059_text_value_2 =
  'まるで　そらでゆれる　色あざやかな　大きな　リボンのよう！\nこんなの　見たことない！ゆらゆら・・・、きらきら・・・。\nあまりに　きれいなので、{{heroName}}{{kidstitle}}は　しばらく　見上げていました。';

export const SEGMENT_18_99059_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_18_99059_text_value_2,
  x: 92,
  y: 488,
};

const SEGMENT_19_99040_text_value_1 =
  '「ありがとう、{{heroName}}{{kidstitle}}が　とても{{strengthA}}、たすかっちゃったよ。\nこれ、どうぞ」と、マノは　{{heroName}}{{kidstitle}}に　プレゼントをわたしました。';

export const SEGMENT_19_99040_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_19_99040_text_value_1,
  x: 159,
  y: 38,
};

const SEGMENT_19_99040_text_value_2 =
  '{{heroName}}{{kidstitle}}は　くうこうに　向かいます。';

export const SEGMENT_19_99040_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_19_99040_text_value_2,
  x: 467,
  y: 141,
};

const SEGMENT_19_99050_text_value_1 =
  '「{{heroName}}{{kidstitle}}の　{{strengthB}}は　とってもすてきだよ。\nこれ、気にいって　くれるかな？」\nアンナは　{{heroName}}{{kidstitle}}に　プレゼントを　手わたしました。';

export const SEGMENT_19_99050_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_19_99050_text_value_1,
  x: 232,
  y: 33,
};

const SEGMENT_19_99050_text_value_2 =
  '{{heroName}}{{kidstitle}}は　くうこうに　向かいます。\n{{strengthC}}だなんて、ちょっぴり　てれくさい。';

export const SEGMENT_19_99050_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_19_99050_text_value_2,
  x: 391,
  y: 132,
};

const SEGMENT_19_99060_text_value_1 =
  '「{{heroName}}{{kidstitle}}は　とっても{{strengthD}}ね。\nはい、これ。今日のことを　おぼえていてほしいから」';

export const SEGMENT_19_99060_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_19_99060_text_value_1,
  x: 139,
  y: 51,
};

const SEGMENT_19_99060_text_value_2 =
  'ニコから　プレゼントを　もらって、\n{{heroName}}{{kidstitle}}は　くうこうに　向かいました';

export const SEGMENT_19_99060_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_19_99060_text_value_2,
  x: 345,
  y: 144,
};

const SEGMENT_22_99061_text_value_1 =
  '{{heroName}}{{kidstitle}}は　インドのしゅと、デリーに\n着きました。たくさんの人！とっても　にぎやかです。\nジリジリ・・・と、たいようが　てりつけ、からそうな\nかおりもします。ここにすむ　ダーシャと　会いました。';

export const SEGMENT_22_99061_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_22_99061_text_value_1,
  x: 21,
  y: 144,
};
const SEGMENT_22_99061_text_value_2 = 'ナマステ！\n{{heroName}}{{kidstitle}}！';

export const SEGMENT_22_99061_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_138541_15,
  text: SEGMENT_22_99061_text_value_2,
  x: 227,
  y: 262,
  rotation: -9,
};

const SEGMENT_23_99062_text_value_1 =
  '「このかおりはね、カレーに使う　スパイスの　かおりだよ！」';

export const SEGMENT_23_99062_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_23_99062_text_value_1,
  x: 98,
  y: 38,
};

const SEGMENT_23_99062_text_value_2 =
  '「ここに来たら、{{heroName}}{{kidstitle}}に　見てほしいものが　あるんだ！」';

export const SEGMENT_23_99062_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_23_99062_text_value_2,
  x: 310,
  y: 98,
};
const SEGMENT_23_99062_text_value_3 = '「電車で行こう！」';

export const SEGMENT_23_99062_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_23_99062_text_value_3,
  x: 652,
  y: 210,
};

const SEGMENT_24_99063_text_value_1 =
  '駅についた　{{heroName}}{{kidstitle}}たち。';

export const SEGMENT_24_99063_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99063_text_value_1,
  x: 60,
  y: 25,
};

const SEGMENT_24_99063_text_value_2 =
  'すると、「あれ？どの電車　だったかなぁ・・」\nダーシャは　のる電車が　わからないみたい。';

export const SEGMENT_24_99063_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99063_text_value_2,
  x: 23,
  y: 78,
};
const SEGMENT_24_99063_text_value_3 =
  '「そんな時、つらそうに　すわっている子に　気づきました。\n「あまりにあつくて　クラクラしちゃって」と、\nその子は　言います。';

export const SEGMENT_24_99063_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99063_text_value_3,
  x: 310,
  y: 188,
};

const SEGMENT_24_99065_text_value_1 =
  '駅についた　{{heroName}}{{kidstitle}}たち。\nしかし、「あ、あのぅ・・・」と、\nダーシャは　チケットうりばで　タジタジ。';

export const SEGMENT_24_99065_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99065_text_value_1,
  x: 102,
  y: 70,
};

const SEGMENT_24_99065_text_value_2 =
  '見ると、チケットうりばの　おじさんが、\nこわ～いかおをして　すわっているじゃありませんか！\nなにかに　おこっているようです！\nだれも　話しかけられそうにありません！';

export const SEGMENT_24_99065_text_2: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99065_text_value_2,
  width: 842,
  x: 181,
  y: 450,
};

const SEGMENT_24_99067_text_value_1 =
  '{{heroName}}{{kidstitle}}と　ダーシャは\nもくてきちに　向かう電車に　のりました。';

export const SEGMENT_24_99067_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99067_text_value_1,
  x: 28,
  y: 31,
};

const SEGMENT_24_99067_text_value_2 = 'しかし　とつぜん！';

export const SEGMENT_24_99067_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99067_text_value_2,
  x: 243,
  y: 94,
};
const SEGMENT_24_99067_text_value_3 = '電車が　止まってしまいました。';

export const SEGMENT_24_99067_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99067_text_value_3,
  x: 503,
  y: 100,
};
const SEGMENT_24_99067_text_value_4 =
  '見ると、牛がせんろの上にねそべり、とおせんぼをしています。\n牛はここでは　しんせいないきもの。\nむりにうごかさず、牛がじぶんで　どくのを　まつしかないみたい。\nでも、いつになるか　わからないから　こまりました！';

export const SEGMENT_24_99067_text_4: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_24_99067_text_value_4,
  width: 842,
  x: 122,
  y: 471,
};

const SEGMENT_25_99064_text_value_1 =
  'そこで、だれにでも　やさしい　{{heroName}}{{kidstitle}}は\nもっていた水を　その子に　あげました。\n\nその子は「ありがとう！助かったよ！」と言って\n水を　いっきに　のみほし、すっかり　元気になりました。';

export const SEGMENT_25_99064_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99064_text_value_1,
  x: 47,
  y: 166,
};

const SEGMENT_25_99064_text_value_2 =
  '「あれを　見にいくんだろう？\nそれなら、あそこのホームからでる　電車にのりなよ」\nと、おしえてくれました。';

export const SEGMENT_25_99064_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99064_text_value_2,
  x: 245,
  y: 413,
};
const SEGMENT_25_99064_text_value_3 =
  'そして　ふたりは\nもくてきちに　向かう　電車にのることが　できました。';

export const SEGMENT_25_99064_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99064_text_value_3,
  x: 363,
  y: 524,
};

const SEGMENT_25_99066_text_value_1 =
  'でも　どんなあいてにも　ゆうかんな　{{heroName}}{{kidstitle}}は、\nすぅっを　いきをすいこんでから、大きな声で';

export const SEGMENT_25_99066_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99066_text_value_1,
  x: 99,
  y: 62,
};

const SEGMENT_25_99066_text_value_2 = 'と、言いました。';

export const SEGMENT_25_99066_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99066_text_value_2,
  x: 100,
  y: 200,
};
const SEGMENT_25_99066_text_value_3 =
  'おじさんは　その声におどろき、目をあけました。\nなぁんだ、いねむりを　していたんですね！';

export const SEGMENT_25_99066_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99066_text_value_3,
  x: 357,
  y: 275,
};
const SEGMENT_25_99066_text_value_4 =
  'ふたりは　ぶじに　チケットを買い、\n電車に　のることができました。';

export const SEGMENT_25_99066_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99066_text_value_4,
  x: 510,
  y: 522,
};

const SEGMENT_25_99068_text_value_1 =
  'でもどんな時も　アイデアでかいけつする　はっそうゆたかな　{{heroName}}{{kidstitle}}は、';

export const SEGMENT_25_99068_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99068_text_value_1,
  x: 74,
  y: 35,
};

const SEGMENT_25_99068_text_value_2 = 'と言い、';

export const SEGMENT_25_99068_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99068_text_value_2,
  x: 332,
  y: 112,
};
const SEGMENT_25_99068_text_value_3 =
  '電車をおりて　牛に近づいていきました。\n手には、牛がだいすきな　ほしくさを　持っています。\nほしくさで、牛をあんぜんな所に　ゆうどうしたのです。';

export const SEGMENT_25_99068_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99068_text_value_3,
  x: 74,
  y: 154,
};
const SEGMENT_25_99068_text_value_4 =
  'そうして、電車は　もくてきちに向けて\nぶじに　走りだしたのでした。';

export const SEGMENT_25_99068_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_25_99068_text_value_4,
  x: 494,
  y: 446,
};

const SEGMENT_26_99069_text_value_1 =
  '「これが　せかいいさんの、タージマハルだよ！」ダーシャが　言いました。\n\n白く　かがやく　たてもの！なんとぜんぶ、石で　できてるんですって！\nとっても　しんぴてきな　そのすがたに　あっとうされてしまいます。';

export const SEGMENT_26_99069_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_26_99069_text_value_1,
  x: 38,
  y: 31,
};

const SEGMENT_26_99069_text_value_2 =
  '「このたてものを　見るために　毎日　たくさんの人が　ここに来るんだよ」\nそれもそのはず、だってこんなに　きれいなんですもの。';

export const SEGMENT_26_99069_text_2: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_26_99069_text_value_2,
  width: 842,
  x: 0,
  y: 516,
};

const SEGMENT_27_99070_text_value_1 =
  '「ありがとう　{{heroName}}{{kidstitle}}、おかげで　ここまで　たどりつくことができたね。\n{{heroName}}{{kidstitle}}の　その{{strength}}を、いつまでも　だいじにしてね」';

export const SEGMENT_27_99070_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_27_99070_text_value_1,
  x: 151,
  y: 28,
};

const SEGMENT_27_99070_text_value_2 =
  'ダーシャはそう言い、{{heroName}}{{kidstitle}}に　プレゼントをわたしました。';

export const SEGMENT_27_99070_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_27_99070_text_value_2,
  x: 262,
  y: 112,
};
const SEGMENT_27_99070_text_value_3 =
  '{{heroName}}{{kidstitle}}は　くうこうに　向かいます。';

export const SEGMENT_27_99070_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBoldKunchan_black_14,
  text: SEGMENT_27_99070_text_value_3,
  x: 483,
  y: 186,
};

const SEGMENT_01_text_value_1 =
  'この　広いせかいで　せいちょうしていく　{{heroName}}{{kidstitle}}へ';

export const SEGMENT_01_text_1: TextElement = {
  align: 'center',
  style: ZenMaruGothicBoldKunchan_white_14,
  text: SEGMENT_01_text_value_1,
  width: 842,
  x: 0,
  y: 80,
};
