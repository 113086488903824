export const KEY_KIDS_TITLE_INDEX = '{{kidstitleIndex}}';
export const KEY_KIDS_TITLE = '{{kidstitle}}';
export const COUNTRY01 = '{{Country01}}';
export const COUNTRY02 = '{{Country02}}';
export const FROM1 = '{{from1}}';
export const FROM2 = '{{from2}}';
export const STRENGTH = '{{strength}}';
export const STRENGTHA = '{{strengthA}}';
export const STRENGTHB = '{{strengthB}}';
export const STRENGTHC = '{{strengthC}}';
export const STRENGTHD = '{{strengthD}}';
export const STRENGTHE = '{{strengthE}}';
export const STRENGTHF = '{{strengthF}}';
export const STRENGTHF2 = '{{strengthF2}}';
